<template>
  <div class="text-input">
    <label v-bind:class="labelClass">{{ label }}</label>
    <div class="input-box">
      <input
        v-bind:type="type ? type : 'text'"
        v-bind:value="value"
        v-bind:placeholder="labelClass === '' ? label : ''"
        @click="focusInput()"
        @blur="blurInput()"
        v-on:input="updateValue($event.target.value)"
        v-bind:maxlength="maxLength ? parseInt(maxLength) : -1"
        v-bind:disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFocused: false
    }
  },
  name: 'TextInput',
  props: ['value', 'label', 'type', 'maxLength', 'disabled'],
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },
    focusInput: function () {
      this.isFocused = true
    },
    blurInput: function () {
      this.isFocused = false
    }
  },
  computed: {
    labelClass() {
      if (this.value.length > 0 || this.isFocused) {
        return 'show'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.text-input {
  width: 237px;
  height: 50px;
  text-align: left;

  label {
    width: 100%;
    height: 14px;
    font-family: Barlow;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: $greyish-brown;
    text-transform: uppercase;
    opacity: 0;
  }
  label.show {
    opacity: 1;
  }

  .input-box {
    display: block;
    width: 100%;
    height: 36px;

    input {
      width: 100%;
      height: 100%;
      padding: 0 3px;
      border: none;
      border-bottom: solid 1px $greyish-brown;
      outline: none;
      font-family: Barlow;
      font-size: 16px;
      color: $greyish-brown;
    }

    input[type='text']::placeholder {
      font-family: Barlow;
      font-size: 16px;
      color: $greyish-brown;
    }
  }
}
</style>
