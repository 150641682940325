<template>
  <notifications
    group="admin-actions"
    position="top right"
    width="370px"
    :duration="5000"
  >
    <template v-slot:body="props">
      <div class="notification activity-notifications">
        <div class="notification-icon">
          <img :src="props.item.data.iconPath" alt="notification icon" />
        </div>
        <div class="notification-body">
          <div class="notification-title">{{ props.item.title }}</div>
          <div class="notification-content" v-html="props.item.text"></div>
        </div>
        <div class="notification-close" @click="props.close">
          <img src="@/assets/close.svg" alt="Circle with X" />
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {}
</script>

<style lang="scss">
.notification-wrapper {
  pointer-events: none;
}

div .activity-notifications {
  display: grid;
  grid-auto-columns: 40px auto 40px;
  grid-auto-rows: auto;
  align-items: center;
  border-radius: 6px;
  margin: 16px 16px 8px 8px;
  padding: 24px;
  font-family: Barlow;
  color: $greyish-brown;
  background-color: $egg-shell;
  pointer-events: none;

  .notification-title {
    grid-column: 1;
  }

  .notification-body {
    grid-column: 2;
    .notification-title {
      font-size: 16px;
      font-weight: 500;
    }

    .notification-content {
      font-size: 14px;
    }
  }

  .notification-close {
    grid-column: 3;
    pointer-events: all;
    justify-self: flex-end;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }
}
</style>
