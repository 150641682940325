import Util from './util.js'

export default class EquipmentStore {
  constructor(client, events) {
    this.$client = client
    this.$events = events
    this.totalEquipment = 0
    this.equipment = []
    this.mapEquipment = new Map()
  }

  async loadAll(companyId, filters, readOnly = false) {
    let response = await this.$client.readEquipment(companyId, filters)

    if (readOnly && filters) {
      let queryArr = filters.query.split(' ')

      response.data.forEach((equipment) => {
        equipment.points = 0
        if (filters.query !== '') {
          queryArr.forEach((key) => {
            let title = equipment.title.toLowerCase()
            equipment.name = equipment.title
            equipment.points = equipment.points + (title.split(key).length - 1)
          })
        }
      })

      return Util.sortByKey(response.data, 'points', true)
    }

    if (!readOnly) {
      this.equipment = response.data
      this.totalEquipment = response.totalEquipment

      // Generate the ui string for the assignments
      for (let x = 0; x < this.equipment.length; x++) {
        if (!this.equipment[x].assignments) this.equipment[x].assignments = []

        let assignmentString = ''
        for (let i = 0; i < this.equipment[x].assignments.length; i++) {
          assignmentString +=
            this.equipment[x].assignments[i].driver.firstName +
            ' ' +
            this.equipment[x].assignments[i].driver.lastName
          if (i < this.equipment[x].assignments.length - 1) {
            assignmentString += ', '
          }
        }
        this.equipment[x].assignmentString = assignmentString
      }

      this.equipment.forEach((equip) => {
        this.mapEquipment.set(equip.id, equip)
      })

      if (
        filters &&
        filters.equipmentType &&
        filters.equipmentType.length > 0
      ) {
        this.$events.$emit(filters.equipmentType + '_refresh', {
          equipment: this.equipment,
          totalEquipment: this.totalEquipment
        })
      } else {
        this.$events.$emit('equipment_refresh', {
          equipment: this.equipment,
          totalEquipment: this.totalEquipment
        })
      }

      return this.equipment
    }
  }

  async add(equipment) {
    let response = await this.$client.createEquipment(equipment)

    return response
  }

  async updateEquipmentById(id, body) {
    let response = await this.$client.updateEquipmentById(id, body)

    return response
  }

  async validateEquipment(file, companyId, type) {
    let validateResults = await this.$client.validateEquipment(
      file,
      companyId,
      type
    )

    return validateResults
  }

  async importEquipment(file, companyId, type) {
    let importResults = await this.$client.importEquipment(
      file,
      companyId,
      type
    )

    return importResults
  }

  async deleteEquipmentById(id) {
    await this.$client.deleteEquipmentById(id)
  }
  async readEquipment(companyId, driverId) {
    let equipment = await this.$client.readEquipment(companyId, driverId)

    return equipment
  }
}
