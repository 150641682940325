<template>
  <div class="import-modal vue-modal">
    <div class="background-overlay"></div>
    <div
      class="box"
      v-bind:class="{
        fileSelection: fileSelection,
        importPreview: importPreview
      }"
    >
      <div class="header">
        <h3 class="title">{{ importModal.title }}</h3>
      </div>
      <div class="content">
        <div class="import-preview">
          <div v-show="failedValidation" class="import-preview">
            <div class="result-text">
              We detected
              <span v-if="errorCount > 0" class="bold"
                >{{ errorCount }} error(s)</span
              >
              in your import file. Below is a preview of these errors. Please
              make the appropriate changes and click on 'change file' to
              reupload.
            </div>
            <table id="error-table">
              <thead id="error-header">
                <tr id="error-table-header">
                  <th align="left">Row</th>
                  <th align="left">Issue</th>
                </tr>
              </thead>
              <tbody v-if="errorCount > 0">
                <tr
                  class="table-row"
                  v-for="(errror, index) in exampleErrors"
                  v-bind:key="index"
                >
                  <td align="left">{{ exampleErrors[index].line }}</td>
                  <td align="left">{{ exampleErrors[index].message }}</td>
                </tr>
              </tbody>
            </table>
            <div class="import-button-row">
              <div class="flex-row">
                <secondary-button
                  class="button-secondary"
                  label="Cancel"
                  v-bind:clicked="cancel"
                ></secondary-button>
                <button class="button button-primary" v-on:click="newFile">
                  Change File
                </button>
              </div>
            </div>
          </div>
          <div v-show="validationPassed" class="import-preview">
            <div class="result-text">
              We detected
              <span v-if="userCount > 0" class="bold"
                >{{ userCount }} new users</span
              >
              <span v-if="equipmentCount > 0" class="bold"
                >{{ equipmentCount }} new equipment</span
              >
              in your import file.
              <br />Below is a preview of the import. If everything looks
              <br />correct, click on Import to start the process.
            </div>
            <table id="user-table" v-if="userCount > 0">
              <thead id="user-header">
                <tr id="user-table-header">
                  <th style="padding-left: 36px" align="left">Row</th>
                  <th align="left">First Name</th>
                  <th align="left">Last Name</th>
                  <th align="left">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-row"
                  v-for="(user, index) in exampleImport"
                  v-bind:key="index"
                >
                  <td style="padding-left: 36px" align="left">
                    {{ exampleImport[index].line }}
                  </td>
                  <td align="left">
                    {{ exampleImport[index].data[firstNameIndex] }}
                  </td>
                  <td align="left">
                    {{ exampleImport[index].data[lastNameIndex] }}
                  </td>
                  <td align="left">
                    {{ exampleImport[index].data[emailIndex] }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table id="equipment-table" v-if="equipmentCount > 0">
              <thead id="equipment-header">
                <tr id="equipment-table-header">
                  <th align="left">Row</th>
                  <th style="padding-left: 104px" align="left">Unit Number</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-row"
                  v-for="(equipment, index) in exampleImport"
                  v-bind:key="index"
                >
                  <td align="left">{{ exampleImport[index].line }}</td>
                  <td style="padding-left: 104px" align="left">
                    {{ exampleImport[index].data[0] }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="import-button-row">
              <div class="flex-row">
                <secondary-button
                  class="button-secondary"
                  label="Change File"
                  v-bind:clicked="newFile"
                ></secondary-button>
                <button class="button button-primary" v-on:click="runImport">
                  <img
                    class="import-loading-spinner"
                    src="../assets/loader.png"
                    v-if="loading"
                  />
                  <div v-show="!loading" class="flex-row">
                    <div style="padding-top: 3px; margin-right: 8px">
                      <img src="../assets/import.png" alt="import arrow" />
                    </div>
                    Import
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-show="fileLoadView" class="addFile">
          <div class="row">
            <div class="number-wrapper">
              <label class="number">1.</label>
            </div>
            <label class="instruction-text">
              To bulk import, download the template
              <a v-bind:href="this.downloadLink" download>file</a> and enter
              your content.
            </label>
          </div>
          <div class="row">
            <div class="number-wrapper">
              <label class="number">2.</label>
            </div>
            <label class="instruction-text"
              >Drop your .csv file in the section below.</label
            >
          </div>
          <ImportBox ref="importBox" @fileAdded="buttonReady" @file="setFile" />
          <div class="button-row">
            <div class="button-box">
              <secondary-button
                class="button-secondary"
                v-bind:label="importModal.cancelText"
                v-bind:clicked="cancel"
              ></secondary-button>
              <button v-show="loading" class="checkLoad">
                <img
                  class="import-loading-spinner"
                  src="../assets/loader.png"
                  v-if="loading"
                />
              </button>
              <primary-button
                v-show="!loading"
                v-bind:label="importModal.confirmText"
                v-bind:clicked="confirm"
                :disabled="!fileAdded"
              ></primary-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'
import ImportBox from '../components/ImportBox.vue'
import UserIcon from '@/assets/user.png'
import TrailerIcon from '@/assets/trailer.png'
import TruckIcon from '@/assets/truck.png'

export default {
  name: 'ImportModal',
  components: { ImportBox, PrimaryButton, SecondaryButton },
  props: ['importModal'],
  data() {
    return {
      inputValue: '',
      file: '',
      fileName: '',
      importedFileUrl: '',
      previewImage: '',
      loading: false,
      fileAdded: false,
      validationSent: false,
      validationPassed: false,
      userCount: '',
      equipmentCount: '',
      errorCount: '',
      exampleErrors: '',
      exampleImport: '',
      importType: '',
      firstNameIndex: '',
      lastNameIndex: '',
      emailIndex: '',
      companyId: ''
    }
  },
  created() {
    if (this.importModal.defaultValue) {
      this.inputValue = this.importModal.defaultValue
    }
    if (this.importModal.importedFileUrl) {
      this.importedFileUrl = this.importModal.importedFileUrl
      this.file = true
      this.fileName = ''
    }
    this.currentTitle = this.importModal.title
    this.importType = this.currentTitle.split(' ')[1]
    this.downloadLink =
      this.importType == 'Member'
        ? 'https://res.cloudinary.com/permitbook-dev/raw/upload/v1563212554/downloads/userImportTemplate.csv'
        : 'https://res.cloudinary.com/permitbook-dev/raw/upload/v1563212554/downloads/equipmentImportTemplate.csv'
    this.companyId = this.importModal.companyId
  },
  methods: {
    newFile: function () {
      this.importModal.title = this.currentTitle
      this.fileAdded = false
      this.validationSent = false
      this.loading = false
      this.validationPassed = false
      this.$refs.importBox.newFile()
    },
    buttonReady(value) {
      this.fileAdded = value
    },
    confirm: async function () {
      if (this.importModal.callback) {
        this.importModal.title = 'Import Confirmation'
        this.validationSent = true
        this.loading = true
        let validation = await this.importModal.callback(true, this.file)
        if (validation.errorList) {
          this.validationPassed = false
          this.errorCount = validation.errorList.length
          this.exampleErrors = validation.errorList.slice(0, 4)
        } else if (validation.users) {
          this.validationPassed = true
          this.userCount = validation.users.length - 1
          this.cancelText = 'Change File'
          this.confirmText =
            '<img src="@/assets/import.png" alt="import arrow" /> Import'
          this.exampleImport = validation.users.slice(1, 5)

          let headerRow = validation.users[0].data

          for (let i = 0; i < headerRow.length; i++) {
            if (headerRow[i].toLowerCase() == 'first name')
              this.firstNameIndex = i
            if (headerRow[i].toLowerCase() == 'last name')
              this.lastNameIndex = i
            if (headerRow[i].toLowerCase() == 'email') this.emailIndex = i
          }
        } else if (validation.equipment) {
          this.validationPassed = true
          this.equipmentCount = validation.equipment.length - 1
          this.cancelText = 'Change File'
          this.confirmText =
            '<img src="@/assets/import.png" alt="import arrow" /> Import'
          this.exampleImport = validation.equipment.slice(1, 5)
        } else {
          this.$events.$emit('hideImportModal')
          this.$events.$emit('showBasicModal', {
            mode: 'confirm',
            title: 'Validation Error',
            text: `We could not validate the file because the columns do not use the correct naming. Click <a href="${this.downloadLink}" download>here</a> to download our template.`,
            secondaryText: 'Please edit your document and try again.',
            confirmText: 'Back to Import',
            noCancel: true,
            downloadLink: this.downloadLink,
            html: true,
            callback: () => {
              this.$events.$emit('showImportModal', {
                title: this.currentTitle,
                cancelText: this.cancelText,
                confirmText: this.confirmText,
                callback: this.importModal.callback
              })
            }
          })
        }

        this.loading = false
      }
    },
    cancel: async function () {
      if (this.importModal.callback) {
        this.importModal.callback(false, this)
      }
      this.$events.$emit('hideImportModal')
    },
    runImport: async function () {
      this.loading = true
      let results
      try {
        if (this.userCount) {
          results = await this.$userStore.importUsers(this.file, this.companyId)

          this.$notify({
            group: 'admin-actions',
            title: 'Members Imported',
            text: `<span style="font-weight: 500;">${results.passingUsers} new members</span> were successfully imported`,
            data: {
              iconPath: UserIcon
            }
          })
        } else if (this.equipmentCount) {
          results = await this.$equipmentStore.importEquipment(
            this.file,
            this.companyId,
            this.importType
          )

          this.$notify({
            group: 'admin-actions',
            title: `${this.importType}s Imported`,
            text: `<span style="font-weight: 500;">${
              results.createdCount
            } new ${this.importType.toLowerCase()}s</span> were successfully imported`,
            data: {
              iconPath: this.importType == 'Truck' ? TruckIcon : TrailerIcon
            }
          })
        }
      } catch (err) {
        this.$events.$emit('hideImportModal')
        this.$events.$emit('showBasicModal', {
          mode: 'confirm',
          title: 'Import Error',
          text: `We could not import the file due to internal errors. Please try again later or contact your system administrator.`,
          confirmText: 'Back to Import',
          noCancel: true,
          downloadLink: this.downloadLink,
          html: true,
          callback: () => {
            this.$events.$emit('showImportModal', {
              title: this.currentTitle,
              callback: this.importModal.callback
            })
          }
        })
      }
      this.loading = false
      this.$events.$emit('hideImportModal')
      let refreshData = { equipment: '', totalEquipment: '' }
      let refreshType = this.importType.toLowerCase() + '_refresh'

      this.$events.$emit(refreshType, refreshData)
    },
    setFile: function (file) {
      this.file = file
    }
  },
  computed: {
    fileSelection() {
      return !this.validationSent || (this.validationSent && this.loading)
    },
    importPreview() {
      return (this.validationSent && !this.loading) || this.validationPassed
    },
    failedValidation() {
      return !this.validationPassed && this.validationSent && !this.loading
    },
    fileLoadView() {
      return (
        !this.validationSent ||
        (this.validationSent && this.loading && !this.validationPassed)
      )
    }
  }
}
</script>

<style lang="scss">
.import-modal {
  z-index: 100;
  .header {
    h3.title {
      font-size: 28px !important;
      height: 100%;
      display: inline-block;
    }
  }
  .fileSelection {
    width: 702px;
    height: 547px;
    left: calc(50vw - 369px);
    top: calc(50vh - 239px);
  }

  .importPreview {
    width: 956px;
    height: 530px;
    left: calc(50vw - 480px);
    top: calc(50vh - 231px);
  }

  .box {
    .content {
      .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 55px;
        margin-bottom: 16px;
      }

      .number-wrapper {
        width: 29px;
        margin-right: 5px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .instruction-text {
        font-family: Barlow;
        font-size: 16px;
        line-height: 19px;
        color: #505050;
        margin-bottom: 11px;
      }

      .number {
        font-size: 39px;
        font-family: Poppins;
        color: #333333;
      }
    }

    .button-row {
      display: flex;
      justify-content: center;
    }

    .button-box {
      width: 194px;
      height: 51px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .buttonReady {
      color: #124e81;
    }

    .button-primary {
      height: 51px;
      width: 168px;
      margin-left: 24px;
      display: flex;
      justify-content: center;
      flex-direction: row;
    }

    .result-text {
      margin-top: 21px;
      margin-bottom: 32px;
      width: 460px;
      font-family: Barlow;
      font-size: 18px;
      line-height: 22px;
      color: #505050;
    }

    .import-button-row {
      margin-top: 32px;
      display: flex;
      justify-content: center;

      .flex-row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .import-preview {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .button-secondary {
      font-family: Barlow;
      font-size: 16px;
      font-weight: 500;
      color: #505050;
    }

    #user-table {
      width: 735px;
    }

    #equipment-table {
      width: 450px;
    }

    #user-table th {
      font-family: Barlow;
      font-size: 16px;
      line-height: 46px;
      color: #ffffff;
      background-color: $light-navy;
    }

    #user-table td {
      line-height: 38px;
      font-family: Barlow;
      font-size: 18px;
      color: #505050;
    }

    #error-table th {
      padding-left: 36px;
      font-family: Barlow;
      font-size: 16px;
      line-height: 46px;
      color: #ffffff;
      background-color: $light-navy;
    }

    #error-table td {
      line-height: 38px;
      font-family: Barlow;
      padding-left: 36px;
      padding-right: 36px;
      font-size: 18px;
      color: #505050;
    }

    #equipment-table th {
      padding-left: 36px;
      font-family: Barlow;
      font-size: 16px;
      line-height: 46px;
      color: #ffffff;
      background-color: $light-navy;
    }

    #equipment-table td {
      line-height: 38px;
      font-family: Barlow;
      padding-left: 36px;
      font-size: 18px;
      color: #505050;
    }
  }

  .box.importPreview {
    max-width: 956px;
  }

  .import-loading-spinner {
    height: 24px;
    animation: spin 1s infinite linear;
    -webkit-animation: spin 1s infinite linear;
    z-index: 101;
  }

  .checkLoad {
    width: 116px;
    height: 51px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    background-color: $light-navy;
    color: $white;
    display: flex;
    justify-content: center;
  }

  .bold {
    font-weight: 500;
  }
}
</style>
