<template>
  <div class="basic-modal company-modal vue-modal" v-bind:class="modalClass">
    <div class="background-overlay"></div>
    <div class="box">
      <div class="header">
        <h3 class="companyModalTitle">{{ companyModal.title }}</h3>
      </div>
      <div class="content">
        <div v-if="companyModal.mode === 'confirm'">
          <img src="../assets/warning.png" />
          <p
            v-if="companyModal.html"
            class="primary-text"
            v-html="companyModal.text"
          ></p>
          <p v-else class="primary-text">{{ companyModal.text }}</p>
          <p v-if="companyModal.secondaryText" class="secondary-text">
            {{ companyModal.secondaryText }}
          </p>
          <secondary-button
            v-if="!companyModal.noCancel"
            v-bind:label="companyModal.cancelText"
            v-bind:clicked="cancel"
          ></secondary-button>
          <primary-button
            v-bind:class="{ wide: companyModal.noCancel }"
            v-bind:label="companyModal.confirmText"
            v-bind:clicked="confirm"
          ></primary-button>
        </div>
        <div v-if="companyModal.mode === 'ok'">
          <p>{{ companyModal.text }}</p>
          <primary-button
            v-bind:label="companyModal.confirmText"
            v-bind:clicked="cancel"
          ></primary-button>
        </div>
        <div v-if="companyModal.mode === 'input'">
          <text-input label="Name" v-model="inputValue"></text-input>
          <div class="company-managed-container">
            <input type="checkbox" id="managedCheckbox" v-model="managed" />
            <label for="managedCheckbox"
              >No subscription required (Managed)</label
            >
          </div>
          <div class="company-actions-container">
            <secondary-button
              v-bind:label="companyModal.cancelText"
              v-bind:clicked="cancel"
            ></secondary-button>
            <primary-button
              v-bind:label="companyModal.confirmText"
              v-bind:clicked="confirm"
              v-bind:disabled="!inputValue"
            ></primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '../components/TextInput'
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'
export default {
  name: 'CompanyModal',
  components: { TextInput, PrimaryButton, SecondaryButton },
  props: ['companyModal'],
  data() {
    return {
      inputValue: '',
      managed: false,
      file: false,
      fileName: ''
    }
  },
  created() {
    if (this.companyModal.inputValue) {
      this.inputValue = this.companyModal.inputValue
      this.managed = this.companyModal.managed
    }
  },
  methods: {
    confirm: async function () {
      if (this.companyModal.callback) {
        this.companyModal.callback(true, this)
      }
      this.$events.$emit('hideCompanyModal')
    },
    cancel: async function () {
      if (this.companyModal.callback) {
        this.companyModal.callback(false, this)
      }
      this.$events.$emit('hideCompanyModal')
    }
  },
  computed: {
    modalClass: function () {
      let classVal = this.companyModal.mode + '-modal'
      if (this.companyModal.secondaryText) {
        classVal += ' show-secondary-text'
      }
      return classVal
    }
  }
}
</script>

<style lang="scss">
.company-modal {
  z-index: 100;
  .box {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 570px;

    .content {
      padding: 32px 32px;

      .primary-text {
        margin-top: 32px;
      }

      .secondary-text {
        margin-top: 24px;
      }
    }
  }
}
.company-modal.input-modal {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    .content {
      .text-input {
        width: 100%;
        margin-bottom: 0px;
      }
      .primary-button {
        margin-left: 17px;
      }
    }
  }
  .company-actions-container {
    margin-top: 32px;
  }
  .company-managed-container {
    text-align: left;
    margin-top: 20px;
    #managedCheckbox {
      height: 24px;
      width: 24px;
      display: inline-block;
      vertical-align: middle;
    }
    label {
      font-family: Barlow;
      font-size: 16px;
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.company-modal.ok-modal p,
.company-modal.confirm-modal p {
  font-family: Barlow;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: $greyish-brown;
}
.company-modal.confirm-modal {
  .box {
    width: 498px;
    p {
      padding: 0 10px;
      font-family: Barlow;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      text-align: center;
      line-height: normal;
      color: $greyish-brown;
    }
    .primary-button {
      margin: 32px 0px 0px 17px;
    }

    .wide {
      width: 189px;
    }
  }
}
.company-modal.confirm-modal.show-secondary-text {
  .box {
    width: 498px;
  }
}
</style>
