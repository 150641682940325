import Config from '../config/config'

let userError = function (firebaseError) {
  switch (firebaseError) {
    case 'auth/too-many-requests':
    case 'auth/wrong-password':
      return new Error('Incorrect Password')
    case 'auth/user-not-found':
      return new Error('Incorrect Email')
    default:
      return new Error('Invalid login attempt.')
  }
}

export default class Session {
  constructor(firebase, client, events, router) {
    this.client = client
    this.firebase = firebase
    this.$events = events
    this.$router = router
    this.firebase.initializeApp(Config.get().firebase)
    this.currentUser = {}
    this.userRoles = []
    this.myCompanies = []
    this.isSuperAdmin = false
    this.currentCompany
    this.cookieState = {}
    this.loggingIn = false
    this.wasLoggedIn = false
    this.dataLoaded = false

    setInterval(() => {
      if (!this.loggingIn) {
        this.client.verifySessionIntegrity()
      }
    }, 60000)
  }

  /* Adds a reference to the Vue-Cookies object, since it's not initialized early enough,
    and checks to see if there's a session cookie
  */
  async initializeVueCookies(cookies) {
    this.$cookies = cookies
    this.$cookies.config(this.client.getSessionDuration(), '/') // 15 minutes
    let cookie = this.$cookies.get('pbfbt')
    if (cookie && cookie.t) {
      this.cookieState = cookie
      this.client.setAuthSync(
        this.$cookies,
        this.cookieState.u,
        this.cookieState.t
      )
      this.wasLoggedIn = true
      await this.loadData()
    } else {
      await this.logOut()
    }
  }

  async requestPasswordReset(email) {
    try {
      await this.firebase.auth().sendPasswordResetEmail(email)
    } catch (err) {
      if (err.code === 'auth/invalid-email') {
        let err = new Error('Invalid email address.')
        err.invalidCredentials = true
        throw err
      } else if (err.code === 'auth/user-not-found') {
        let err = new Error('Account could not be found.')
        err.invalidCredentials = true
        throw err
      } else {
        throw err
      }
    }
  }

  isSignedIn() {
    if (this.cookieState && this.cookieState.t) {
      return true
    } else {
      return false
    }
  }

  async loadData() {
    this.dataLoaded = false
    this.currentUser = await this.client.readUser(this.cookieState.u)
    this.userRoles = await this.client.readRoles(this.cookieState.u)

    let userCompanies = await this.client.readUserCompanies(this.cookieState.u)

    this.myCompanies = []

    // Figure out what kind of user this is
    for (let i = 0; i < this.userRoles.length; i++) {
      if (this.userRoles[i].role === 'super_admin') {
        this.isSuperAdmin = true
        this.myCompanies = []
        break
      } else if (
        this.userRoles[i].role === 'org_admin' ||
        (this.userRoles[i].role === 'acc_admin' &&
          this.userRoles[i].status != 'blocked')
      ) {
        let companyMatch
        for (let x = 0; x < userCompanies.length; x++) {
          if (userCompanies[x].id === this.userRoles[i].companyId) {
            companyMatch = userCompanies[x]
            break
          }
        }
        if (companyMatch) {
          this.myCompanies.push({
            id: companyMatch.id,
            role: this.userRoles[i].role,
            name: companyMatch.name,
            status: companyMatch.status
          })
        } else {
          throw new Error('Strange error: company for user role not found.')
        }
      }
    }

    // See if user has no admin role at all
    if (!this.isSuperAdmin && this.myCompanies.length === 0) {
      let err = new Error('Not Authorized')
      err.status = 403
      throw err
    } else {
      this.dataLoaded = true
      // If the user is on the login page or an admin page, route them to the correct page
      let currentPath = window.location.pathname.toLowerCase()
      if (
        currentPath.indexOf('login') > -1 ||
        this.isAuthorizedPathSync(currentPath)
      ) {
        await this.setDefaultPage()
      }
      this.$events.$emit('admin_updated', this.getUserData())
      return this.getUserData()
    }
  }

  // Pushes an org admin into one of their company pages, or a super admin to the users tab
  async setDefaultPage() {
    let currentPath = window.location.pathname.toLowerCase()
    let parts = currentPath.split('/')
    if (!this.isSuperAdmin) {
      if (currentPath.indexOf('/org/') > -1 && parts.length > 1) {
        let success = this.updateCurrentCompany(parts[2])
        if (!success) {
          this.updateCurrentCompany(this.myCompanies[0].id, true)
        }
      } else {
        this.updateCurrentCompany(this.myCompanies[0].id)
      }
    } else {
      if (currentPath.indexOf('/login') > -1) {
        this.$router.push('/admin/user')
      } else if (this.isAuthorizedPathSync(currentPath)) {
        if (currentPath.indexOf('/org/') > -1 && parts.length > 1) {
          if (parts[2] !== 'null') {
            await this.updateSuperAdminCurrentCompany(null, parts[2])
          } else {
            this.$router.push('/admin/user')
          }
        }
      }
    }
  }

  async updateSuperAdminCurrentCompany(company, companyId) {
    try {
      // Clearing the current company
      if (!company && !companyId) {
        this.currentCompany = null
        this.$events.$emit('admin_updated', this.getUserData())
      }

      // Load the company data since it's not available locally
      if (!company && companyId) {
        company = await this.client.readCompanyById(companyId)
        if (!company) {
          throw new Error('This company could not be found.')
        }
      }
      this.currentCompany = JSON.parse(JSON.stringify(company))
      this.$events.$emit('admin_updated', this.getUserData())
    } catch (err) {
      this.$events.$emit('error', err)
      this.$router.push('/admin/user')
    }
  }

  updateCurrentCompany(companyId, forceReload, preventRedirect) {
    for (let i = 0; i < this.myCompanies.length; i++) {
      if (this.myCompanies[i].id === companyId) {
        if (!this.currentCompany && !forceReload) {
          this.currentCompany = this.myCompanies[i]

          let currentPath = window.location.pathname.toLowerCase()
          if (currentPath.indexOf(companyId) === -1 && !preventRedirect) {
            // If no company is yet opened, push it to the router
            this.$router.push(
              `/org/${this.currentCompany.id}/management/members`
            )
          }
          this.$events.$emit('admin_updated', this.getUserData())
          return true
        } else {
          // If moving from one company to the next, refresh the page
          window.location.href = `/org/${this.myCompanies[i].id}/management/members`
          return true
        }
      }
    }

    return false
  }

  updateUser(firstName, lastName, email) {
    this.currentUser.firstName = firstName
    this.currentUser.lastName = lastName
    this.currentUser.email = email
    this.$events.$emit('admin_updated', this.getUserData())
  }

  getUserData() {
    return {
      user: this.currentUser,
      myCompanies: this.myCompanies,
      currentCompany: this.currentCompany,
      isSuperAdmin: this.isSuperAdmin,
      cookieState: this.cookieState,
      dataLoaded: this.dataLoaded
    }
  }

  async logIn(emailAddress, password) {
    this.loggingIn = true
    this.authToken = ''

    try {
      await this.firebase
        .auth()
        .setPersistence(this.firebase.auth.Auth.Persistence.SESSION)

      await this.firebase
        .auth()
        .signInWithEmailAndPassword(emailAddress, password)

      this.wasLoggedIn = true
    } catch (error) {
      await this.logOut()
      throw userError(error.code)
    }

    // Save cookie
    let t = await this.firebase.auth().currentUser.getIdToken(true)
    let u = this.firebase.auth().currentUser.uid
    this.cookieState = {
      t: t,
      u: u
    }
    this.$cookies.set('pbfbt', this.cookieState)
    this.client.setAuthSync(
      this.$cookies,
      this.cookieState.u,
      this.cookieState.t
    )
    const lData = await this.loadData()
    this.loggingIn = false
    return lData
  }

  async logOut(excludeRefresh) {
    this.$cookies.remove('pbfbt')
    this.cookieState = {}
    this.userRoles = []
    this.myCompanies = []
    this.dataLoaded = false
    this.isSuperAdmin = false
    this.currentCompany = null
    this.client.setAuthSync(
      this.$cookies,
      this.cookieState.u,
      this.cookieState.t
    )
    await this.firebase.auth().signOut()

    // Only refresh if the user was actually logged into the dashboard and loaded data
    if (excludeRefresh) this.wasLoggedIn = false
    this.$events.$emit('logged_out', { refresh: this.wasLoggedIn })
  }

  isAuthorizedPathSync(path) {
    if (
      path.indexOf('admin') > -1 ||
      path.indexOf('documents') > -1 ||
      path.indexOf('org') > -1
    ) {
      return true
    }
    return false
  }
}
