<template>
  <div class="bulk-actions-modal vue-modal">
    <div class="background-overlay"></div>
    <div class="box">
      <div class="header">
        <h3>Bulk Actions</h3>
      </div>
      <div class="content">
        <div>
          <img src="../assets/warning.png" />
          <p class="primary-text">User Actions</p>
          <p class="secondary-text">
            Really update {{ this.bulkActionsModal.userIds.length }} users?
          </p>
          <secondary-button
            label="Cancel"
            v-bind:clicked="cancel"
          ></secondary-button>
          <primary-button
            label="Verify Users"
            v-bind:clicked="verifyUsers"
          ></primary-button>
          <primary-button
            label="Send SMS"
            v-bind:clicked="sendSms"
          ></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'
export default {
  name: 'BulkActionsModal',
  components: { PrimaryButton, SecondaryButton },
  props: ['bulkActionsModal'],
  data() {
    return {
      userIds: []
    }
  },
  created() {
    if (this.bulkActionsModal.userIds) {
      this.userIds = this.bulkActionsModal.userIds
    }
  },
  methods: {
    verifyUsers: async function () {
      this.$events.$emit('showLoading')
      await this.$client.bulkVerifyUsers(this.userIds)
      this.$events.$emit('hideLoading')
      this.bulkActionsModal.callback(true)
    },
    sendSms: async function () {
      this.$events.$emit('showLoading')
      await this.$client.sendSms(this.userIds)
      this.$events.$emit('hideLoading')
      this.bulkActionsModal.callback(true)
    },
    cancel: async function () {
      this.bulkActionsModal.callback(false)
    }
  }
}
</script>

<style lang="scss">
.bulk-actions-modal {
  .box {
    .content {
      .primary-text {
        margin-top: 32px;
      }

      .secondary-text {
        margin-top: 24px;
      }
    }
    width: 498px;
    p {
      padding: 0 10px;
      font-family: Barlow;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      text-align: center;
      line-height: normal;
      color: $greyish-brown;
    }
    .primary-button {
      margin: 32px 0px 0px 17px;
    }

    .wide {
      width: 189px;
    }
  }
}
</style>
