<template>
  <div id="invite-user-modal" class="vue-modal">
    <div class="background-overlay"></div>
    <div class="box">
      <div class="header">
        <h3 class="title">{{ title }}</h3>
        <a class="close-fullscreen" v-on:click="cancel">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
            <g fill="#124E81" fill-rule="nonzero">
              <path
                d="M1.91.795l16.263 16.263a1 1 0 1 1-1.415 1.415L.495 2.209A1 1 0 1 1 1.909.795z"
              />
              <path
                d="M16.758.795L.495 17.058a1 1 0 1 0 1.414 1.415L18.173 2.209A1 1 0 1 0 16.758.795z"
              />
            </g>
          </svg>
        </a>
      </div>
      <div class="content">
        <div id="fields">
          <div class="field firstRow" v-show="isInvite">
            <text-input
              class="user-firstname"
              label="First name"
              :disabled="this.selectedUser"
              v-model="firstName"
            ></text-input>
          </div>
          <div class="field firstRow" v-show="isInvite">
            <text-input
              class="user-lastname"
              label="Last name"
              :disabled="this.selectedUser"
              v-model="lastName"
            ></text-input>
          </div>
          <div class="field secondRow">
            <text-input
              label="Email"
              v-model="email"
              @input="initSuggestions"
            ></text-input>
            <div
              id="suggestions-container"
              v-if="suggestions && suggestions.length !== 0"
            >
              <div
                v-bind:key="suggestion._id"
                class="suggestion"
                v-for="suggestion in suggestions"
                v-on:click="setSuggestion(suggestion)"
              >
                <span>{{ suggestionString }}</span>
                <p>{{ suggestion.email }}</p>
              </div>
            </div>
          </div>
          <div class="field secondRow">
            <multiselect
              class="multi-select role-multi-select"
              label="title"
              track-by="role"
              v-model="role.selected"
              selectLabel
              deselectLabel
              selectedLabel
              :options="role.options"
              :option-width="237"
              :option-height="40"
              :searchable="false"
              :allowEmpty="false"
              open-direction="bottom"
              @input="updateRoleSelected"
            >
              <template v-slot:singleLabel="props">
                <span
                  class="option__label"
                  v-if="role.selected.role !== 'blank'"
                  >Access</span
                >
                <span class="option__title">{{ props.option.title }}</span>
                <img src="@/assets/multiselect-arrow.png" class="arrow" />
              </template>
              <template v-slot:option="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.title }}</span>
                </div>
              </template>
            </multiselect>

            <div
              v-on:click="toggleHasAppAccess"
              class="phone"
              v-bind:class="{
                hasAppAccess:
                  this.hasAppAccess || this.role.selected.role === 'driver'
              }"
            >
              <label>App</label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="nonzero"
                  d="M16.5 1h-8A2.5 2.5 0 0 0 6 3.5v17A2.5 2.5 0 0 0 8.5 23h8a2.5 2.5 0 0 0 2.5-2.5v-17A2.5 2.5 0 0 0 16.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H8V4h9v14z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div id="buttons">
          <secondary-button
            label="Cancel"
            v-bind:clicked="cancel"
          ></secondary-button>
          <primary-button
            label="Save"
            v-bind:clicked="invite"
            v-bind:disabled="formDisabled"
          ></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import TextInput from '../components/TextInput'
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'
import userEditIcon from '@/assets/user-bg.svg'

export default {
  name: 'InviteUserModal',
  components: { Multiselect, TextInput, PrimaryButton, SecondaryButton },
  props: ['inviteUserModal'],
  data() {
    return {
      title: 'Invite User',
      isInvite: true,
      users: [],
      suggestionString: '',
      suggestions: [],
      firstName: '',
      lastName: '',
      email: '',
      selectedUser: null,
      hasAppAccess: false,
      role: {
        selected: {
          title: 'Access',
          role: 'blank'
        },
        options: [
          {
            title: 'Driver',
            docType: 'driver',
            role: 'driver'
          },
          {
            title: 'Manager',
            docType: 'acc_admin',
            role: 'acc_admin'
          }
        ]
      }
    }
  },
  created() {
    if (this.inviteUserModal.title) {
      this.title = this.inviteUserModal.title
    }
    this.isInvite = this.inviteUserModal.isInvite
    if (this.inviteUserModal.user) {
      this.fillEdit(this.inviteUserModal.user)
    }
  },
  mounted: async function () {
    // const companyId = this.$session.getUserData().currentCompany.id
    // const users = await this.$userStore
    //   .loadAll({ forInvite: true }, false, false, true)
    //   .then((users) => {
    //     if (typeof users !== 'undefined' && users) {
    //       this.$set(this, 'users', users)
    //     }
    //   })
  },
  methods: {
    updateRoleSelected: function (selectedRole) {
      this.role.selected.role = selectedRole.role
      this.hasAppAccess = false
    },
    fillEdit: function (user) {
      this.firstName = this.inviteUserModal.user.firstName
      this.lastName = this.inviteUserModal.user.lastName
      this.email = this.inviteUserModal.user.email
      // this.inviteUserModal.hasAppAccess = false
      let hasAppAccess = false
      this.mode = 'edit'
      let selectedRole = { title: 'Access', role: 'blank' }
      this.$set(this, 'selectedUser', user)
      for (let role of this.inviteUserModal.user.roles) {
        if (role.role === 'driver') {
          hasAppAccess = true
          // this.inviteUserModal.hasAppAccess = true
          this.hasAppAccess = true
        }
        if (role.role === 'acc_admin') {
          selectedRole.role = 'acc_admin'
          selectedRole.title = 'Manager'
        }
      }
      if (hasAppAccess && selectedRole.role === 'blank') {
        selectedRole.role = 'driver'
        selectedRole.title = 'Driver'
      } else {
        this.hasAppAccess = hasAppAccess
      }
      this.role.selected = selectedRole
    },
    initSuggestions: function (value) {
      const users = this.users
      const suggestions = []
      this.mode = 'create'
      if (value !== '') {
        users.forEach((user) => {
          if (value.toLowerCase() == user.email.toLowerCase()) {
            suggestions.push(user)
          }
        })

        this.$set(this, 'suggestionString', value)
        this.$set(this, 'suggestions', suggestions)
        return
      }

      this.$set(this, 'suggestions', [])
      this.$set(this, 'hasAppAccess', false)
      this.$set(this, 'selectedUser', null)
    },
    setSuggestion: function (user) {
      this.$set(this, 'hasAppAccess', true)

      this.$set(this, 'email', user.email)
      this.$set(this, 'firstName', user.firstName)
      this.$set(this, 'lastName', user.lastName)
      this.role.selected.role = 'driver'
      this.role.selected.title = 'Driver'

      this.$set(this, 'selectedUser', user)

      this.$set(this, 'suggestions', [])
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    invite: async function () {
      try {
        if (this.inviteUserModal.onlyCallback) {
          this.inviteUserModal.callback(true, this)
          this.$events.$emit('hideInviteUserModal')
        } else {
          this.$events.$emit('showLoading')
          // Validate emails and get the params based on whether this is an edit or an add
          let companyId = this.$session.getUserData().currentCompany.id
          let email = ''
          let firstName = ''
          let lastName = ''
          if (
            this.selectedUser &&
            this.role.selected.role !== 'role' &&
            this.mode === 'edit'
          ) {
            if (!this.validateEmail(this.selectedUser.email)) return
            email = this.selectedUser.email
            firstName = this.selectedUser.firstName
            lastName = this.selectedUser.lastName
          } else if (this.mode === 'create') {
            if (!this.validateEmail(this.suggestionString)) {
              return
            }
            email = this.suggestionString
            firstName = this.firstName
            lastName = this.lastName
          } else {
            throw new Error('A strange error occurred. Please try again.')
          }
          // Invite the user
          let currentRoles = []
          let newRoles = []
          let lessRoles = []

          if (this.mode == 'create') {
            newRoles.push(this.role.selected.role)
            if (this.role.selected.role != 'driver' && this.hasAppAccess) {
              newRoles.push('driver')
            }
          } else {
            for (let role of this.inviteUserModal.user.roles) {
              currentRoles.push(role.role)
            }
            if (this.hasAppAccess && !this.inviteUserModal.hasAppAccess) {
              newRoles.push('driver')
            }
            if (!this.hasAppAccess && this.inviteUserModal.hasAppAccess) {
              for (let role of this.inviteUserModal.user.roles) {
                if (role.role == 'driver') {
                  lessRoles.push(role)
                }
              }
            }
            if (
              !currentRoles.includes(this.role.selected.role) &&
              this.role.selected.role != 'driver'
            ) {
              newRoles.push(this.role.selected.role)
            }
            if (
              this.role.selected.role == 'driver' &&
              currentRoles.length > 1
            ) {
              for (let role of this.inviteUserModal.user.roles) {
                if (role.role != 'driver') {
                  lessRoles.push(role)
                }
              }
            }
          }
          let userId = await this.$userStore.add({
            firstName: firstName,
            lastName: lastName,
            email: email,
            role: newRoles[0],
            companyId: companyId
          })
          for (let i = 1; i < newRoles.length; i++) {
            await this.$userStore.createUserRole({
              userId: userId,
              role: newRoles[i],
              companyId: companyId
            })
          }

          for (let lessRole of lessRoles) {
            await this.$userStore.deleteRoleById(lessRole.id)
          }

          this.$events.$emit('hideInviteUserModal')

          this.$notify({
            group: 'admin-actions',
            title: 'Invitation Sent',
            text: 'The user has been updated and will receive an email notification if appropriate.',
            data: {
              iconPath: userEditIcon
            }
          })
          this.$events.$emit('hideLoading')
          if (this.inviteUserModal.callback) {
            this.inviteUserModal.callback(true)
          }
        }
      } catch (err) {
        this.$events.$emit('hideInviteUserModal')
        this.$events.$emit('error', err)
        if (this.inviteUserModal.callback) {
          this.inviteUserModal.callback()
        }
      }
    },
    toggleHasAppAccess: function () {
      if (
        this.role.selected.role === 'driver' ||
        this.role.selected.role === 'blank'
      ) {
        return false
      }

      this.hasAppAccess = !this.hasAppAccess
    },
    cancel: async function () {
      this.$events.$emit('hideInviteUserModal')
      if (this.inviteUserModal.callback) {
        this.inviteUserModal.callback()
      }
    }
  },
  computed: {
    formDisabled() {
      return (
        !this.validateEmail(this.email) || this.role.selected.role === 'blank'
      )
    }
  }
}
</script>

<style lang="scss">
$breakPoint: 600px;
#invite-user-modal {
  .multi-select {
    .arrow {
      position: relative;
      top: unset;
      right: -5px;
    }
  }

  .box {
    #fields {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 24px;
      .secondRow {
        margin-top: 32px;
      }

      .field {
        width: 100%;
        :disabled {
          color: lightgray;
        }
        @media (min-width: $breakPoint) {
          width: calc(50% - 28px);
        }

        &:first-of-type {
          margin-bottom: 16px;
          @media (min-width: $breakPoint) {
            margin-bottom: 0;
          }
        }

        .phone {
          width: 24px;
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          z-index: 2;

          label {
            margin-top: 4px;
            margin-bottom: 5px;
            font-family: Barlow;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.3px;
            color: #505050;
            text-transform: uppercase;
          }

          .app-icon {
            height: 22px;
          }
          svg {
            * {
              fill: #afb3bf;
            }
          }
          &.hasAppAccess {
            svg {
              * {
                fill: #124e81;
              }
            }
          }
        }

        .text-input {
          width: 100%;
        }

        .multiselect__tags {
          min-height: none;
        }

        .option__title {
          text-transform: capitalize;
        }
      }
    }

    #suggestions-container {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      border-radius: 3px;
      box-shadow: 0 0 8px 0 rgba(116, 116, 116, 0.1);
      border: solid 1px rgba(80, 80, 80, 0.2);
      background-color: #ffffff;
      z-index: 2;

      .suggestion {
        height: 50px;
        position: relative;
        padding: 8px;
        overflow: hidden;
        border-bottom: solid 1px rgba(80, 80, 80, 0.2);

        &:hover {
          cursor: pointer;
        }

        p,
        span {
          white-space: nowrap;
          position: absolute;
          top: 50%;
          left: 8px;
          transform: translate(0%, -50%);
          font-family: Barlow;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }

      p {
        color: #afb3bf;
      }

      span {
        color: #505050;
        z-index: 2;
      }
    }

    #buttons {
      button {
        margin: 0 12px;
      }
    }
  }
}
</style>
