<template>
  <div id="user-modal" class="user-modal vue-modal fullscreen">
    <div v-show="!submitting" class="submitting-container">
      <div class="background-overlay"></div>
      <div class="box">
        <div class="header">
          <h3 class="title">{{ userModal.title }}</h3>
          <a class="close-fullscreen" v-on:click="cancel">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
              <g fill="#124E81" fill-rule="nonzero">
                <path
                  d="M1.91.795l16.263 16.263a1 1 0 1 1-1.415 1.415L.495 2.209A1 1 0 1 1 1.909.795z"
                />
                <path
                  d="M16.758.795L.495 17.058a1 1 0 1 0 1.414 1.415L18.173 2.209A1 1 0 1 0 16.758.795z"
                />
              </g>
            </svg>
          </a>
        </div>
        <div class="content">
          <div class="double-row">
            <text-input
              class="user-firstname"
              label="First name"
              v-model="firstName"
              @blur="blurTextInput"
            ></text-input>
            <text-input
              class="user-lastname"
              label="Last name"
              v-model="lastName"
            ></text-input>
          </div>
          <div class="double-row">
            <text-input
              class="user-email"
              label="Email"
              v-model="email"
            ></text-input>
            <phone-input
              class="user-phone"
              label="Phone"
              v-model="phoneNumber"
            ></phone-input>
          </div>
          <div class="single-row">
            <text-input
              class="user-validation-code"
              label="Verification Code"
              v-model="verificationCode"
            ></text-input>
          </div>
          <div v-if="showCompanies">
            <div class="single-row search company-select" v-if="isSuperUser">
              <text-input
                label="Find Company"
                v-model="selectedCompanyName"
                @input="initSuggestions"
              ></text-input>

              <div
                id="suggestions-container"
                v-if="companySuggestions.length !== 0"
              >
                <div
                  v-bind:key="companySuggestion._id"
                  class="suggestion"
                  v-for="companySuggestion in companySuggestions"
                  v-on:click="onCompanySelect(companySuggestion)"
                >
                  <span>{{ suggestionString }}</span>
                  <p>{{ companySuggestion.name }}</p>
                </div>
              </div>
              <!-- companies
              <multiselect
                class="multi-select role-multi-select"
                label="name"
                track-by="id"
                v-model="selectedCompanies"
                selectLabel
                deselectLabel
                selectedLabel
                :multiple="true"
                :options="companies"
                :option-width="237"
                :option-height="40"
                :searchable="true"
                :allowEmpty="true"
                open-direction="bottom"
                @select="onCompanySelect"
                @remove="onCompanyRemove"
                placeholder=""
              >
                <template slot="singleLabel">
                  <span class="option__title">{{selectedCompanies.name}}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="8"
                      viewBox="0 0 13 8"
                    >
                      <path
                        fill="#124E81"
                        fill-rule="nonzero"
                        d="M13 1.27L6.5 8 0 1.27 1.226 0 6.5 5.462 11.774 0z"
                      />
                    </svg>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{props.option.name}}</span>
                  </div>
                </template>
              </multiselect>
                           <p>Search Company</p>
            companies-->
            </div>
            <div class="company-access labels" v-if="isSuperUser">
              <div class="company">
                <label>Companies</label>
              </div>
              <div class="access">
                <label>Access</label>
              </div>
              <div class="app">
                <label>App</label>
              </div>
            </div>
            <div
              class="company-access-roles"
              v-if="roles.length !== 0 && isSuperUser"
            >
              <div
                class="company-access selects"
                v-for="(company, index) in userRoles"
                v-bind:key="index"
                v-bind:class="{ remove: roles[index].selected.remove }"
              >
                <div class="company" v-if="roles[index].selected.display">
                  <p>{{ company.companyName }}</p>
                </div>
                <div class="access" v-if="roles[index].selected.display">
                  <multiselect
                    class="multi-select role-multi-select"
                    label="name"
                    track-by="role"
                    v-model="roles[index].selected"
                    selectLabel
                    deselectLabel
                    selectedLabel
                    :options="roles[index].options"
                    :option-width="237"
                    :option-height="40"
                    :searchable="false"
                    :allowEmpty="false"
                    open-direction="bottom"
                    @select="onRoleSelect"
                  >
                    <template v-slot:singleLabel>
                      <span class="option__title">
                        {{ roles[index].selected.title }}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="8"
                          viewBox="0 0 13 8"
                        >
                          <path
                            fill="#124E81"
                            fill-rule="nonzero"
                            d="M13 1.27L6.5 8 0 1.27 1.226 0 6.5 5.462 11.774 0z"
                          />
                        </svg>
                      </span>
                    </template>
                    <template v-slot:option="props">
                      <div class="option__desc">
                        <span class="option__title">{{
                          props.option.title
                        }}</span>
                      </div>
                    </template>
                  </multiselect>
                </div>
                <div class="app" v-if="roles[index].selected.display">
                  <a
                    class="phone"
                    v-bind:class="{
                      active: roles[index].selected.appAccess,
                      driverPlus:
                        roles[index].selected.appAccess && roles.length > 1
                    }"
                    v-on:click="appAccess(roles[index], index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#124E81"
                        fill-rule="nonzero"
                        d="M16.5 1h-8A2.5 2.5 0 0 0 6 3.5v17A2.5 2.5 0 0 0 8.5 23h8a2.5 2.5 0 0 0 2.5-2.5v-17A2.5 2.5 0 0 0 16.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H8V4h9v14z"
                      />
                    </svg>
                  </a>
                  <a
                    class="close"
                    v-bind:class="{ active: roles[index].selected.appAccess }"
                    v-on:click="onCompanyRemove(roles[index], index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#FFF"
                        fill-rule="evenodd"
                        d="M10.276 9.656l3.252-3.252a.438.438 0 1 1 .62.62l-3.253 3.252 3.252 3.252a.438.438 0 0 1-.619.62l-3.252-3.253-3.252 3.252a.438.438 0 0 1-.62-.619l3.252-3.252-3.252-3.252a.438.438 0 1 1 .62-.62l3.252 3.252z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="buttons">
            <secondary-button
              label="Cancel"
              v-bind:clicked="cancel"
            ></secondary-button>
            <primary-button
              v-if="Object.keys(user).length === 0 && showCompanies"
              label="Add"
              v-bind:clicked="newUser"
              v-bind:disabled="formDisabled"
            ></primary-button>
            <primary-button
              v-if="Object.keys(user).length !== 0 && showCompanies"
              label="Save"
              v-bind:clicked="confirm"
              v-bind:disabled="formDisabled"
            ></primary-button>
            <primary-button
              v-if="!showCompanies"
              label="Update"
              v-bind:clicked="update"
              v-bind:disabled="formDisabled"
            ></primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import TextInput from '../components/TextInput'
import PhoneInput from '../components/PhoneInput'
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'
import userEditIcon from '@/assets/user-bg.svg'

export default {
  name: 'UserModal',
  components: {
    Multiselect,
    TextInput,
    PhoneInput,
    PrimaryButton,
    SecondaryButton
  },
  props: ['userModal'],
  data() {
    let phoneNumber = this.userModal.user.phoneNumber
    if (
      phoneNumber &&
      phoneNumber.length === 12 &&
      phoneNumber.startsWith('+1')
    ) {
      phoneNumber = phoneNumber.substring(2)
    }
    return {
      isSuperUser: false,
      submitting: false,
      user: this.userModal.user,
      userRoles: this.userModal.roles || [],
      firstName: this.userModal.user.firstName || [],
      lastName: this.userModal.user.lastName || '',
      email: this.userModal.user.email || '',
      phoneNumber: this.userModal.user.phoneNumber || '',
      verificationCode: this.userModal.user.verificationCode || '',
      company: '',
      selectedCompanyName: '',
      companies: this.userModal.companies || [],
      selectedCompanies: [],
      suggestions: [],
      companySuggestions: [],
      companySuggestion: null,
      suggestionString: '',
      suggestion: null,
      roles: [],
      showCompanies: this.userModal.showCompanies
    }
  },
  created: async function () {
    this.isSuperUser = this.$session.isSuperAdmin
    if (Object.keys(this.user).length === 0 && this.isSuperUser) {
      const companies = await this.$companyStore.loadAll(false, false)
      this.companies = companies
    }
    this.initSuggestions()

    console.log(this.userModal.showCompanies)

    if (this.userModal.roles) {
      this.roles = this.roleDisplayRules(this.formatRoles(this.userModal.roles))
    }
  },
  methods: {
    blurTextInput: function (e) {
      console.log(e)
    },
    initSuggestions: function (value) {
      const companies = this.companies
      let suggestions = []
      let suggestionCount = 0
      let limitRoles = []
      for (let role of this.userRoles) {
        limitRoles.push(role.companyId)
      }

      if (typeof value !== 'undefined' && value !== '') {
        companies.forEach((company) => {
          const companiesArr = company.name.toLowerCase().split('')
          const valueArr = value.toLowerCase().split('')
          const points = valueArr.length
          let match = 0
          valueArr.forEach((char, charIndex) => {
            if (char === companiesArr[charIndex]) match++
          })
          if (match === points && suggestionCount < 5) {
            if (limitRoles.length < 1) {
              suggestions.push(company)
              suggestionCount = suggestionCount + 1
            } else if (!limitRoles.includes(company.id)) {
              suggestions.push(company)
              suggestionCount = suggestionCount + 1
            }
          }
        })
        this.$set(this, 'suggestionString', value)
        this.$set(this, 'companySuggestions', suggestions)
        return
      }
      this.$set(this, 'companySuggestions', [])
    },
    onCompanySelect(company) {
      this.roles.push({
        options: [
          {
            title: 'Manager',
            docType: 'acc_admin',
            role: 'acc_admin',
            display: true,
            companyId: company.id,
            companyName: company.name,
            new: true,
            remove: false
          },
          {
            title: 'Org Admin',
            docType: 'org_admin',
            role: 'org_admin',
            display: true,
            companyId: company.id,
            companyName: company.name,
            new: true,
            remove: false
          }
        ],
        selected: {
          title: 'Driver',
          appAccess: true,
          companyId: company.id,
          companyName: company.name,
          docType: 'driver',
          role: 'driver',
          userId: this.user.id,
          display: true,
          new: true,
          remove: false
        }
      })

      this.userRoles.push({
        appAccess: true,
        companyId: company.id,
        companyName: company.name,
        docType: 'driver',
        role: 'driver',
        id: company.id,
        userId: this.user.id,
        new: true,
        remove: false
      })
      this.$set(this, 'companySuggestions', [])
    },
    onCompanyRemove: function (company) {
      let id = company.companyId || company.id
      if (company.selected) {
        id = company.selected.companyId || company.selected.id
      }
      const remove = company.selected.remove
      this.userRoles.forEach((key, i) => {
        const idToRemove = key.companyId || key.id
        if (idToRemove === id) {
          let roles = this.roles[i]
          roles.selected.remove = remove ? false : true
          this.$set(this.roles, i, roles)

          let userRoles = this.userRoles[i]
          userRoles.remove = remove ? false : true
          //this.roles.splice(i, 1)
          //this.userRoles.splice(i, 1)
          this.$set(this.userRoles, i, userRoles)

          //this.selectedCompanies.splice(i, 1)
        }
      })
    },
    onRoleSelect: function (role) {
      //assign correct role to selected role
      this.userRoles.forEach((key) => {
        if (role.companyId === key.companyId && role.role !== 'driver') {
          key.role = role.role
          key.docType = role.docType
        }
      })
    },
    formatRoles: function (arr) {
      let rolesArr = []
      //construct roles

      arr.forEach((key) => {
        let role = {
          options: [
            {
              title: 'Manager',
              docType: 'acc_admin',
              role: 'acc_admin',
              display: true,
              new: false,
              remove: false
            },
            {
              title: 'Org Admin',
              docType: 'org_admin',
              role: 'org_admin',
              display: true,
              new: false,
              remove: false
            }
          ]
        }

        role.options.forEach((opt) => {
          opt.id = key.id
          opt.companyId = key.companyId
          opt.companyName = key.companyName
          opt.new = false
          opt.remove = false
        })

        role.selected = key
        role.selected.title = key.role.replace('_', ' ')
        role.selected.docType = key.role

        rolesArr.push(role)
      })

      return rolesArr
    },
    roleDisplayRules: function (roles) {
      const instances = (arr, companyId) =>
        arr.filter((role) => role.selected.companyId === companyId).length
      const appAccess = (arr, companyId) =>
        arr.filter(
          (role) =>
            role.selected.companyId === companyId &&
            role.selected.role === 'driver'
        ).length
      roles.forEach((key) => {
        let display = true
        key.selected.appAccess = false
        key.selected.new = false
        key.selected.remove = false
        if (
          instances(roles, key.selected.companyId) > 1 &&
          key.selected.role === 'driver'
        )
          display = false
        if (key.selected.role === 'super_admin') display = false

        if (appAccess(roles, key.selected.companyId) !== 0) {
          key.selected.appAccess = true
        }

        key.options.forEach((opt) => {
          opt.appAccess = key.selected.appAccess
        })

        if (
          key.selected.role !== 'driver' &&
          appAccess(roles, key.selected.companyId) === 0
        ) {
          key.options.push({
            title: 'Driver',
            docType: 'driver',
            role: 'driver',
            appAccess: true,
            display: true,
            new: false,
            remove: false
          })

          key.options.forEach((opt) => {
            if (opt.role === 'driver') {
              opt.id = key.selected.id
              opt.companyId = key.selected.companyId
              opt.companyName = key.selected.companyName
              opt.new = false
              opt.remove = false
            }
          })
        }
        key.selected.display = display
      })

      return roles
    },
    disableAppAccess: function (role, index) {
      if (role.selected.role === 'driver') return
      let roles = this.roles[index]
      roles.selected.appAccess = false
      roles.options.forEach((opt) => (opt.appAccess = false))
      this.$set(this.roles, index, roles)
    },
    appAccess: function (role, index) {
      if (role.selected.role === 'driver') return
      let roles = this.roles[index]
      let appAccess = roles.selected.appAccess ? false : true
      roles.selected.appAccess = appAccess
      roles.options.forEach((opt) => (opt.appAccess = appAccess))
      this.$set(this.roles, index, roles)
    },
    newUser: async function () {
      try {
        this.$events.$emit('showLoading')

        const formattedPhoneNumber = '+1' + this.phoneNumber

        const newUser = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phoneNumber: formattedPhoneNumber,
          companyId: this.userRoles[0].companyId,
          role: this.userRoles[0].role
        }
        let roles = {}
        this.userRoles.forEach((key, index) => {
          if (index !== 0) {
            roles[key.id].selected = key
          }
        })

        const user = await this.$userStore.add(newUser)
        for (const role in roles) {
          await this.$userStore.createUserRole({
            userId: user,
            role: roles[role].role,
            companyId: roles[role].companyId
          })
        }

        this.$events.$emit('hideLoading')
        this.$events.$emit('hideUserModal')
        this.$notify({
          group: 'admin-actions',
          title: 'Account Created',
          text: `${newUser.firstName} ${newUser.lastName}’s information was successfully created.`,
          data: {
            iconPath: userEditIcon
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    confirm: async function () {
      const b = Object.assign({}, this.roles)
      let A = {}
      let B = {}
      let updatedRoles = {}
      let orgRoles = (orgRoles = await this.userModal.getRoles())
      let a = await this.roleDisplayRules(this.formatRoles(orgRoles))

      //check for new or removed roles
      for (const role in b) {
        const obj = b[role]
        if (obj.selected.new && !obj.selected.remove) {
          await this.userModal.callback(true, this, {
            delete: false,
            create: obj.selected
          })
        }

        if (!obj.selected.new && obj.selected.remove) {
          await this.userModal.callback(true, this, {
            delete: obj.selected,
            create: false
          })
        }
      }

      //find updated roles
      a.forEach((key) => (A[key.selected.id] = key.selected))
      for (const key in b) {
        B[b[key].selected.id] = b[key].selected
      }

      for (const role in A) {
        if (!A[role] || !B[role]) {
          console.log('Roles A or B not found')
          return
        }

        if (
          A[role].role !== B[role].role ||
          A[role].appAccess !== B[role].appAccess
        ) {
          A[role].docType = B[role].docType
          A[role].role = B[role].role
          A[role].title = B[role].title
          A[role].appAccess = B[role].appAccess
          updatedRoles[role] = A[role]
        }
      }

      //manage updated roles
      if (Object.keys(updatedRoles).length !== 0) {
        for (const roleId in updatedRoles) {
          //make app access updates
          const role = updatedRoles[roleId]
          const hasDriverRole = a.filter(
            (r) =>
              r.selected.companyId === role.companyId &&
              r.selected.role === 'driver'
          )

          //if has app access, record is not a driver roll and a driver role DOES exist
          //delete the existing record
          //replace with a new one

          if (
            role.appAccess &&
            role.role !== 'driver' &&
            hasDriverRole.length !== 0
          ) {
            await this.userModal.callback(true, this, {
              delete: role,
              create: role
            })
          }

          if (
            role.appAccess &&
            role.role === 'driver' &&
            hasDriverRole.length !== 0
          ) {
            await this.userModal.callback(true, this, {
              delete: role,
              create: role
            })
          }

          //if has app access and a driver role DOES NOT exist
          //delete the existing record
          //replace with a new one
          //create driver record if need
          if (role.appAccess && hasDriverRole.length === 0) {
            if (role.role === 'driver') {
              await this.userModal.callback(true, this, {
                delete: role,
                create: role
              })
            }

            if (role.role !== 'driver') {
              await this.userModal.callback(true, this, {
                delete: role,
                create: role,
                driver: true
              })
            }
          }

          //if has no app access and a driver role DOES exist
          //delete the existing record
          //delete the driver record
          //replace with a new one
          if (!role.appAccess && hasDriverRole.length !== 0) {
            await this.userModal.callback(true, this, {
              delete: role,
              create: role,
              driver: hasDriverRole[0].selected
            })
          }

          if (!role.appAccess && hasDriverRole.length === 0) {
            await this.userModal.callback(true, this, {
              delete: role,
              create: role
            })
          }
        }
      }

      if (this.phoneNumber.length === 10) {
        this.phoneNumber = '+1' + this.phoneNumber
      }

      if (Object.keys(updatedRoles).length === 0) {
        try {
          const val = await this.userModal.callback(true, this, null)
          console.log({ val })
        } catch (err) {
          this.$events.$emit('hideLoading')
          this.$events.$emit('error', err)
          return
        }
      }

      this.$events.$emit('hideUserModal')
    },
    update: async function () {
      this.$events.$emit('showLoading')

      let formattedPhoneNumber = '+1' + this.phoneNumber
      if (this.phoneNumber == '' || this.phoneNumber == null) {
        formattedPhoneNumber = ''
      }

      const updateUser = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: formattedPhoneNumber,
        verificationCode: this.verificationCode
      }
      const id = this.user.id
      try {
        const val = await this.$userStore.updateUserById(id, updateUser)
        console.log('this:', { val })
      } catch (err) {
        this.$events.$emit('hideLoading')
        this.$events.$emit('error', err)
        return false
      }
      this.$events.$emit('hideLoading')
      this.$events.$emit('hideUserModal')
      this.$notify({
        group: 'admin-actions',
        title: 'Account Created',
        text: `${updateUser.firstName} ${updateUser.lastName}'s information was successfully created.`,
        data: {
          iconPath: userEditIcon
        }
      })
      if (this.userModal.callback) {
        this.userModal.callback(false, this)
      }
    },
    cancel: function () {
      if (this.userModal.callback) {
        this.userModal.callback(false, this)
      }
      this.$events.$emit('hideUserModal')
    },
    validatePhoneNumber: function () {
      console.log(this.phoneNumber)
    }
  },
  computed: {
    formDisabled() {
      if (!this.firstName || !this.lastName || !this.email) {
        return true
      }
      if (!this.showCompanies) {
        return false
      }

      let isSuper = false
      if (this.user) {
        if (this.user.roles) {
          for (let i = 0; i < this.user.roles.length; i++) {
            if (this.user.roles[i].role === 'super_admin') {
              isSuper = true
              break
            }
          }
        }
      }

      if (
        !isSuper &&
        this.selectedCompanies.length === 0 &&
        this.userRoles &&
        this.userRoles.length === 0
      ) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
#user-modal {
  .box {
    position: fixed;
    top: 100px;

    .search {
      .multiselect__tags-wrap,
      .multiselect__input {
        display: none;
      }
    }

    .company-select {
      position: relative;
      &:hover {
        cursor: pointer;
      }

      p {
        position: absolute;
        top: 25px;
        left: 0;
        font-family: Barlow;
        font-size: 16px;
        color: #505050;
        pointer-events: none;
      }
    }
    #suggestions-container {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      border-radius: 3px;
      box-shadow: 0 0 8px 0 rgba(116, 116, 116, 0.1);
      border: solid 1px rgba(80, 80, 80, 0.2);
      background-color: #ffffff;
      z-index: 2;

      .suggestion {
        height: 50px;
        position: relative;
        padding: 8px;
        overflow: hidden;
        border-bottom: solid 1px rgba(80, 80, 80, 0.2);

        &:hover {
          cursor: pointer;
        }

        p,
        span {
          white-space: nowrap;
          position: absolute;
          top: 50%;
          left: 8px;
          transform: translate(0%, -50%);
          font-family: Barlow;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
    }

    .content {
      max-width: 590px;
      max-height: 65vh;
      overflow-y: auto;
      padding-bottom: 50px;

      .double-row {
        height: 50px;
        margin-bottom: 32px;

        .text-input {
          float: left;
        }
        .text-input:first-child {
          margin-right: 32px;
        }
      }

      .double-row:nth-child(2) {
        margin-bottom: 24px;
      }

      .double-row.last {
        margin-bottom: 10px;
      }

      .single-row {
        height: 50px;
        margin-bottom: 31px;

        .text-input {
          width: 100%;
        }
      }

      .single-row.results {
        margin-bottom: 28px;
      }

      .info-row {
        min-height: 12px;
        margin-bottom: 22px;
        text-align: left;
        font-family: Barlow;
        font-size: 12px;
      }

      .role-multi-select {
        width: 237px;
        float: left;
      }

      .role-multi-select {
        width: 100%;
      }

      .company-label {
        position: absolute;
        top: 3px;
        left: 3px;
        font-family: Barlow;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.3px;
        color: $greyish-brown;
        text-transform: uppercase;
      }

      .new-company-label,
      .existing-company-label {
        display: none;
        position: absolute;
        right: 0;
        top: 23px;
        font-family: Barlow;
        font-size: 13px;
        font-weight: 500;
        text-align: right;
        color: $light-navy;
        text-transform: uppercase;
      }

      .no-results {
        .multiselect__content-wrapper {
          display: none;
        }
      }

      .new-company {
        .new-company-label {
          display: block;
        }
      }
      .existing-company {
        .existing-company-label {
          display: block;
        }
      }
    }
  }
  #buttons {
    .primary-button,
    .secondary-button {
      margin: 0 12px;
      margin-bottom: 32px;
    }
    .primary-button {
      margin-right: 20px;
    }
  }
  .company-access-roles {
    margin-bottom: 50px;
  }

  .company-access {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    transition: all 200ms;

    &.remove {
      opacity: 0.5;
      .multiselect,
      .phone {
        pointer-events: none;
      }

      .close {
        transform: rotate(45deg);
      }
    }

    &.labels {
      .access {
        padding-right: 25px;
      }
    }

    .company,
    .access,
    .app {
      width: calc(100% / 3 - 16px);
      text-align: left;
      label {
        @include label;
        width: 100%;
        display: inline-block;
        margin-bottom: 16px;
      }
    }

    .company {
      width: calc(100% - 310px);
      p {
        font-family: Barlow;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #124e81;
      }
    }
    .access {
      width: 160px;
      text-align: right;
      .multiselect {
        width: 100%;
        min-height: 0px;
        .multiselect__single,
        .multiselect__input {
          padding: 0;
          font-family: Barlow;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.19;
          letter-spacing: normal;
        }

        .multiselect__single {
          margin: 0;

          svg {
            margin-left: 8px;
          }
        }
        .multiselect__tags {
          height: auto;
          min-height: 0px;
          border: none;
          text-align: right;
        }

        .multiselect__input {
          min-height: 0px;
          margin: -5px 0 0 0;
        }

        .option__title {
          padding: 0;
          text-transform: capitalize;
        }
      }
    }
    .app {
      width: 75px;
      margin-left: 75px;
      svg {
        width: 20px;
      }

      .phone {
        &:hover {
          cursor: pointer;
        }
        svg {
          * {
            fill: $light-grey;
          }
        }

        &.active {
          svg {
            * {
              fill: #124e81;
              opacity: 0.4;
            }
          }
        }

        &.driverPlus {
          svg {
            * {
              fill: $light-navy;
              opacity: 1;
            }
          }
        }
      }

      .close {
        margin-left: 34px;
        display: inline-block;
        transition: all 200ms;
        &:hover {
          cursor: pointer;
        }
        svg {
          * {
            fill: $light-navy;
          }
        }

        &.active {
          svg {
            * {
              fill: #124e81;
            }
          }
        }
      }
    }
  }
}
</style>
