<template>
  <div class="custom-navbar">
    <!-- Regular Nav Bar -->
    <div v-if="!isWelcomeNavbar">
      <div class="navbar-logo" v-on:click="goHome()">Home</div>
      <div class="org-admin-tabs" v-if="!loggedOut && insideCompany">
        <div
          class="option"
          v-bind:class="{ selected: management }"
          @click="selectManagement"
        >
          Management
        </div>
        <div
          class="option"
          v-bind:class="{ selected: !management }"
          @click="selectDocument"
        >
          Documents
        </div>
      </div>
    </div>

    <div v-if="loggedOut && !isWelcomeNavbar" class="nav">
      <button class="link button button-secondary" v-on:click="showSupport">
        Support
      </button>
      <router-link to="/login" v-if="loggedOut">
        <button class="link button button-primary">Sign In</button>
      </router-link>
      <router-link to="/login" v-else>
        <button class="link button button-primary">Admin</button>
      </router-link>
    </div>

    <div
      v-if="
        !loggedOut &&
        !isSuperAdmin &&
        !hideCompanySelector &&
        !isWelcomeNavbar &&
        hasMultipleCompanies
      "
      class="company-selector"
    >
      <multiselect
        class="multi-select company-selector-multi-select"
        label="name"
        track-by="id"
        v-model="myCompanies.selected"
        selectLabel
        deselectLabel
        selectedLabel
        :options="myCompanies.options"
        group-values="companies"
        group-label="label"
        :group-select="false"
        :option-width="80"
        :option-height="20"
        :searchable="false"
        :allowEmpty="false"
        open-direction="bottom"
        @input="myCompanyChanged"
      >
        <template v-slot:singleLabel="props">
          <span class="option__title">
            <span class="viewing__label">VIEWING:</span>
            <span class="company__title">{{ props.option.name }}</span>
          </span>
          <img src="../assets/multiselect-arrow.png" class="arrow" />
        </template>
        <template v-slot:option="props">
          <div class="option__desc">
            <span
              class="option__title"
              :class="
                props.option.$groupLabel
                  ? 'group'
                  : 'item ' + props.option.status
              "
              :id="
                props.option.$groupLabel
                  ? ''
                  : 'select-company-' + props.option.id
              "
            >
              {{
                props.option.$groupLabel
                  ? props.option.$groupLabel
                  : props.option.name
              }}
            </span>
          </div>
        </template>
      </multiselect>
    </div>

    <div class="nav" v-if="!loggedOut && !isWelcomeNavbar">
      <div
        class="menu"
        v-on:click.prevent="$refs.navDropdown.changeMenuStatus()"
      >
        <div class="dropdown-arrow" aria-label="Profile Menu Dropdown">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
          >
            <path
              fill="#FFF"
              fill-rule="evenodd"
              d="M10 .952L5 6 0 .952.943 0 5 4.096 9.057 0z"
            />
          </svg>
        </div>

        <p class="user-name">{{ fullName }}</p>
      </div>
      <dropdown ref="navDropdown" top="40px" left="-59px">
        <p class="dropdown-item" @click="openAccount()">Account</p>
        <p class="dropdown-item" @click="logOut">Sign Out</p>
      </dropdown>
    </div>

    <!-- Welcome Nav Bar -->
    <div v-if="isWelcomeNavbar" class="welcome-navbar">
      <div class="navbar-logo">Home</div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/DropDownMenu.vue'
import userEditIcon from '@/assets/user-bg.svg'
import CompanyIcon from '@/assets/company.svg'
import Multiselect from 'vue-multiselect'

export default {
  name: 'CustomNavBar',
  components: {
    Dropdown,
    Multiselect
  },
  props: [],
  data() {
    return {
      fullName: `${this.$session.currentUser.firstName}
      ${this.$session.currentUser.lastName}`,
      isSuperAdmin: true,
      insideCompany: true,
      management: true,
      currentCompany: null,
      myCompanies: {
        selected: {
          name: '',
          id: 'null'
        },
        options: []
      },
      hasMultipleCompanies: false,
      hideCompanySelector: false,
      isWelcomeNavbar: false
    }
  },
  created() {
    this.loggedOut = !this.$session.isSignedIn()
    this.showAndHideElements(this.$router.history.current)

    let userData = this.$session.getUserData()
    this.refreshAdmin(userData)

    this.$events.$on('admin_updated', (data) => {
      this.refreshAdmin(data)
    })
  },
  watch: {
    $route(to) {
      this.showAndHideElements(to)
    }
  },
  methods: {
    goHome: async function () {
      if (this.isSuperAdmin) {
        this.$router.push('/admin/user')
      } else {
        if (this.currentCompany) {
          this.$router.push(
            '/org/' + this.currentCompany.id + '/management/members'
          )
        } else {
          try {
            this.$events.$emit('showLoading')
            await this.$session.loadData()
            await this.$session.setDefaultPage()
            this.$events.$emit('hideLoading')
          } catch (err) {
            this.$events.$emit('error', err)
          }
        }
      }
    },
    showAndHideElements: function (to) {
      this.hideCompanySelector = to.path.indexOf('org') === -1
      this.insideCompany =
        to.path.indexOf('org') > -1 &&
        (to.path.indexOf('management') > -1 ||
          to.path.indexOf('documents') > -1)
      this.isWelcomeNavbar =
        to.path.indexOf('welcome') > -1 || to.path.indexOf('pricing') > -1

      this.management = to.path.indexOf('management') > -1
    },
    selectDocument: function () {
      this.management = false
      this.$router.push(
        `/org/${this.myCompanies.selected.id}/documents/company`
      )
    },
    selectManagement: function () {
      this.management = true
      this.$router.push(
        `/org/${this.myCompanies.selected.id}/management/members`
      )
    },
    refreshAdmin: function (data) {
      if (!data.dataLoaded) return

      this.fullName = `${data.user.firstName} ${data.user.lastName}`
      this.isSuperAdmin = data.isSuperAdmin

      this.loggedOut = !this.$session.isSignedIn()

      if (!this.isSuperAdmin) {
        let companies = JSON.parse(JSON.stringify(data.myCompanies))

        let activeCompanies = []
        let inactiveCompanies = []
        for (let i = 0; i < companies.length; i++) {
          if (companies[i].status === 'active') {
            activeCompanies.push(companies[i])
          } else {
            inactiveCompanies.push(companies[i])
          }
        }
        if (activeCompanies.length + inactiveCompanies.length > 1) {
          this.hasMultipleCompanies = true
        }
        this.myCompanies.options = []
        this.$set(this.myCompanies.options, [])
        if (activeCompanies.length > 0) {
          this.myCompanies.options.push({
            label: 'My Companies',
            companies: activeCompanies
          })
        }
        if (inactiveCompanies.length > 0) {
          this.myCompanies.options.push({
            label: 'Inactive Companies',
            companies: inactiveCompanies
          })
        }
      }

      if (data.currentCompany) {
        this.myCompanies.selected.name = data.currentCompany.name
        this.myCompanies.selected.id = data.currentCompany.id
        this.currentCompany = data.currentCompany
      }
    },
    openAccount: function () {
      this.$router.push('/account/info')
    },
    showUserEdit: async function (user) {
      user.companyName = await this.getCompanyName(user.companyId)
      let opts = {
        title: 'Your Account',
        user: user,
        callback: async (confirmed, data) => {
          try {
            if (!confirmed) return
            this.$events.$emit('showLoading')
            let companyId = data.company.selected.id
            if (companyId === 'new_company') {
              companyId = await this.$companyStore.add(
                data.company.selected.name
              )

              if (companyId) {
                this.$notify({
                  group: 'admin-actions',
                  title: 'Created Company',
                  text: `${data.company.selected.name} was successfully created`,
                  data: {
                    iconPath: CompanyIcon
                  }
                })
              }
            }

            let user = {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              companyId: companyId,
              role: data.role.selected.title
            }

            if (data.password.length > 0) {
              user.password = data.password
            }

            await this.$userStore.updateUserById(data.userModal.user.id, user)

            await this.$session.loadData()

            this.$notify({
              group: 'admin-actions',
              title: 'Updated Account',
              text: `Your information was successfully updated`,
              data: {
                iconPath: userEditIcon
              }
            })
          } catch (err) {
            return err // Handled by user modal
          } finally {
            this.$events.$emit('hideLoading')
          }
        }
      }
      this.$events.$emit('showUserModal', opts)
    },
    logOut: async function () {
      await this.$session.logOut()
      this.$router.push('/')
    },
    showSupport() {
      this.$events.$emit('showSupportModal')
    },
    getCompanyName: function (companyId) {
      return this.$companyStore.getCompanyName(companyId)
    },
    myCompanyChanged: function () {
      this.$session.updateCurrentCompany(this.myCompanies.selected.id)
    }
  }
}
</script>

<style lang="scss">
.custom-navbar {
  position: relative;
  min-height: 100px;
  width: 100%;
  background-image: linear-gradient(to right, $mid-blue, $light-navy);
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 23px 32px;
  z-index: 30;

  .navbar-logo {
    float: left;
    width: 40px;
    height: 40px;
    padding: 0px;
    outline: none;
    border: none;
    background-color: transparent;
    background-image: url('../assets/short-logo.png');
    background-size: cover;
    cursor: pointer;
    pointer-events: all;
    align-self: flex-start;
    color: $transparent;
  }

  .normal-navbar {
    width: 100%;
  }

  .welcome-navbar {
    width: 100%;
    text-align: center;

    .navbar-logo {
      display: inline-block;
      float: none;
      cursor: default;
    }
  }

  .org-admin-tabs {
    float: left;

    .option {
      display: inline-block;
      margin-left: 32px;
      padding-top: 5px;
      font-family: Poppins;
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fffffe;
      cursor: pointer;
    }

    .option.selected {
      border-bottom: 1px solid white;
      line-height: 23px;
    }
  }

  button.button {
    background-color: rgba(0, 0, 0, 0);
    width: 116px;
    height: 51px;
    color: $white;
    margin: 0px;
  }

  button.button-primary {
    border: 1px solid $mid-blue;
  }

  a {
    text-decoration: none;
  }

  .company-selector {
    width: 347px;
    height: 43px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 10px);

    .multi-select {
      width: 347px;
      margin-top: -10px;
      float: right;
      text-align: left;

      .arrow {
        top: 17px;
        right: 17px;
      }

      .multiselect__tags {
        height: 43px;
        border-bottom: 0;
      }

      .multiselect__single {
        height: 43px;
        padding: 8px 17px 17px 17px;
        border-radius: 4px;
        box-shadow: 0 0 8px 0 rgba(116, 116, 116, 0.1);
        border: solid 1px rgba(80, 80, 80, 0.2);

        .option__title {
          font-family: Barlow;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.3px;
          color: #505050;
        }

        .viewing__label {
          display: block;
          float: left;
          height: 19px;
          margin-right: 4px;
          padding-top: 4px;
        }

        .company__title {
          display: block;
          float: left;
          white-space: nowrap;
          overflow: hidden;
          max-width: 220px;
          width: 220px;
          font-family: Barlow;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #124e81;
          text-overflow: ellipsis;
        }
      }

      .multiselect__single span,
      .multiselect__placeholder span {
        margin-right: 18px;
      }

      .multiselect__content {
        padding-bottom: 15px;
        text-align: left;
        border-radius: 4px;
        box-shadow: 0 0 8px 0 rgba(116, 116, 116, 0.1);
        background-color: #ffffff;
      }

      .multiselect__option {
        min-height: 30px;
        height: 30px;
        padding: 5px 24px 0;
        background-color: white !important;
        border-bottom: none;

        .option__title {
          display: inline-block;
          padding: 0;
          white-space: nowrap;
          overflow: hidden;
          max-width: 290px;
          width: 290px;
          font-family: Barlow;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #124e81;
          text-overflow: ellipsis;
        }
        .option__title.inactive {
          padding: 0;
          font-family: Barlow;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #afb3bf;
        }
      }

      .multiselect__option--group {
        height: 30px;
        min-height: 30px;
        padding: 19px 24px 20px;

        .option__title {
          padding: 0;
          font-family: Barlow;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.3px;
          color: #505050;
          text-transform: uppercase;
        }
      }

      .multiselect__element:hover {
        .multiselect__option {
          .option__title.item {
            color: $mid-blue;
          }
        }
      }
    }

    .multi-select.multiselect--active {
      .multiselect__single {
        border-radius: 0;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 25px;
    width: auto;
    cursor: pointer;

    p {
      text-shadow: 1px 1px 0 $black-trans;
      font-family: Poppins;
      font-size: 1.125em;
      color: $white;
    }

    .dropdown-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.25em;
      height: 1.25em;
      border-radius: 50%;
      border: 1.5px solid $white;
      margin: 0px 16px;

      &:hover {
        background-color: $mid-blue;
      }

      &:active {
        background-color: $white;
        svg * {
          fill: $light-navy;
        }
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .nav {
    display: none;
  }

  .custom-navbar {
    justify-content: center;
  }
}
</style>
