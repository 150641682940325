<template>
  <div class="page-box">
    <div class="content-box">
      <div class="intro-box">
        <img class="logo" src="../assets/long-logo.png" alt="PermitBook logo" />
        <slot />
      </div>
    </div>
    <CustomFooter />
  </div>
</template>

<script>
import CustomFooter from '@/components/CustomFooter'

export default {
  components: {
    CustomFooter
  }
}
</script>

<style lang="scss">
.page-box {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(../assets/background.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.content-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
}

.intro-box {
  background-color: $black-trans;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .logo {
    background-color: transparent;
    max-width: 236px;
    height: auto;
    padding: 0px;
    margin-top: 32px;
  }
}

@media (orientation: landscape) and (max-width: $landscape-width) {
  .intro-box {
    background-color: $black-trans;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .logo {
      background-color: transparent;
      max-width: 236px;
      height: auto;
      padding: 0px;
      margin-top: 32px;
    }
  }
}

@media (orientation: portrait) and (max-width: $mobile-width) {
  .intro-box {
    background-color: rgba(0, 0, 0, 0);
  }

  @media (orientation: portrait) and (max-width: $mobile-width) {
    .intro-box {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
</style>
