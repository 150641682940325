import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ '@/pages/Home.vue')
    },
    {
      path: '/admin/:type',
      name: 'Management',
      meta: {
        requiresAuth: true,
        layout: 'information'
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "admin" */ '@/pages/Admin.vue')
    },
    {
      path: '/org/:companyId/management/:type',
      name: '-company_name-',
      meta: {
        requiresAuth: true,
        layout: 'information'
      },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "admin" */ '@/pages/Organization-Management.vue'
        )
    },
    {
      path: '/org/:companyId/documents/:type',
      name: 'Documents',
      meta: {
        requiresAuth: true,
        layout: 'information'
      },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "admin" */ '@/pages/Organization-Documents.vue'
        )
    },
    {
      path: '/account/:type',
      name: 'My Account',
      meta: {
        requiresAuth: true,
        layout: 'information'
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "admin" */ '@/pages/Account.vue')
    },
    {
      path: '/welcome',
      name: '',
      meta: { layout: 'information' },
      component: () =>
        import(/* webpackChunkName: "faq" */ '@/pages/Welcome.vue')
    },
    {
      path: '/pricing',
      name: 'Pricing Estimate',
      meta: { layout: 'information' },
      component: () =>
        import(/* webpackChunkName: "faq" */ '@/pages/Pricing.vue')
    },
    {
      path: '/faq',
      name: 'Frequently Asked Questions',
      meta: { layout: 'information' }
    },
    {
      path: '/login',
      name: 'Login',
      component: () =>
        import(/* webpackChunkName: "login" */ '@/pages/Login.vue')
    },
    {
      path: '/forgot',
      name: 'Forgot Password',
      component: () =>
        import(/* webpackChunkName: "forgot" */ '@/pages/Forgot.vue')
    },
    {
      path: '/privacy',
      name: 'Privacy Policy',
      meta: { layout: 'information' },
      component: () =>
        import(/* webpackChunkName: "privacy" */ '@/pages/Privacy.vue')
    },
    {
      path: '/terms',
      name: 'Terms and Conditions',
      meta: { layout: 'information' },
      component: () =>
        import(/* webpackChunkName: "terms" */ '@/pages/Terms.vue')
    }
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
