<template>
  <div class="subscription-matrix-modal vue-modal">
    <div class="background-overlay"></div>
    <div class="box">
      <div class="header">
        <h3 class="title">
          {{
            subscriptionMatrixModal.mode === 'required'
              ? 'Subscription required'
              : 'Pricing Estimate'
          }}
        </h3>
      </div>
      <div class="content" :class="subscriptionMatrixModal.mode">
        <p
          v-show="subscriptionMatrixModal.mode === 'required'"
          class="description"
        >
          Your current subscription level (Free) only allows one driver. To
          create more, please upgrade to a paid subscription.
        </p>

        <div
          class="slider-box"
          v-if="subscriptionMatrixModal.mode === 'required'"
        >
          <h2>How many drivers do you plan on having?</h2>
          <div class="info-row">
            <div class="info-item driver">
              <div class="driver-title">{{ selectedPlanDrivers.range }}</div>
              <div class="driver-sub-title">
                driver{{ selectedPlanDrivers.plural ? 's' : '' }}
              </div>
            </div>
            <div class="info-item monthly-cost">
              <div class="pricing-title">${{ selectedPlanPrice }}</div>
              <div class="pricing-sub-title">monthly cost</div>
            </div>
            <div style="clear: both"></div>
          </div>
          <range-slider
            class="slider"
            min="0"
            :max="plan.options.length - 1"
            step="1"
            v-model="sliderValue"
          ></range-slider>
          <br />
          <br />
        </div>

        <div
          class="slider-box"
          v-if="subscriptionMatrixModal.mode === 'pricing'"
        >
          <h2>How many drivers do you plan on having?</h2>
          <div class="info-row">
            <div class="info-item driver">
              <div class="driver-title">
                {{ selectedPlanDrivers.range }}
                <span class="driver-sub-title"
                  >driver{{ selectedPlanDrivers.plural ? 's' : '' }}</span
                >
              </div>
            </div>
            <range-slider
              class="slider"
              min="0"
              :max="plan.options.length - 1"
              step="1"
              v-model="sliderValue"
            ></range-slider>
            <div class="info-item monthly-cost">
              <div class="pricing-title">${{ selectedPlanPrice }}</div>
              <span class="pricing-sub-title">monthly cost</span>
            </div>
            <div style="clear: both"></div>
          </div>
          <br />
          <br />
        </div>

        <!--
        <p class="question">
          Need more than 40 drivers?
          <span class="contact-us" v-on:click="openContact()">Contact us</span> to talk about custom pricing
        </p>
        -->

        <div
          class="button-row required"
          v-show="subscriptionMatrixModal.mode === 'required'"
        >
          <secondary-button label="Cancel" :clicked="cancel"></secondary-button>
          <primary-button label="Add Card" :clicked="confirm"></primary-button>
        </div>
        <div
          class="button-row pricing"
          v-show="subscriptionMatrixModal.mode === 'pricing'"
        >
          <primary-button label="Close" :clicked="confirm"></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'
import RangeSlider from 'vue-range-slider'
import 'vue-range-slider/dist/vue-range-slider.css'

export default {
  name: 'SubscriptionMatrixModal',
  components: { PrimaryButton, SecondaryButton, RangeSlider },
  props: ['subscriptionMatrixModal'],
  data() {
    return {
      sliderValue: 0,
      plan: {
        options: []
      }
    }
  },
  created() {},
  methods: {
    async setUp() {
      try {
        this.$events.$emit('showLoading')
        this.plan = await this.$subscriptionStore.getCurrentPlanOffering()
        console.log(this.plan)
        this.$events.$emit('hideLoading')
      } catch (err) {
        this.$events.$emit('error', err)
      }
    },
    openContact() {
      this.cancel()
      this.$events.$emit('showSupportModal')
    },
    confirm: async function () {
      if (this.subscriptionMatrixModal.callback) {
        this.subscriptionMatrixModal.callback(true)
      }
      this.$events.$emit('hideSubscriptionMatrixModal')
    },
    cancel: async function () {
      if (this.subscriptionMatrixModal.callback) {
        this.subscriptionMatrixModal.callback(false)
      }
      this.$events.$emit('hideSubscriptionMatrixModal')
    }
  },
  mounted: function () {
    this.setUp()
  },
  computed: {
    selectedPlanDrivers() {
      if (this.sliderValue >= this.plan.options.length) {
        return ''
      } else {
        let option = this.plan.options[this.sliderValue]
        let to = '-' + option.to
        let plural = option.to > 1

        // Handle the 500+ tier
        if (!option.to) {
          to = ''
          plural = true
        }
        if (option.to === option.from) {
          to = ''
        }

        return {
          range: option.from + to,
          plural: plural
        }
      }
    },
    selectedPlanPrice() {
      if (this.sliderValue >= this.plan.options.length) {
        return ''
      } else {
        let option = this.plan.options[this.sliderValue]
        return this.getFormattedCurrency(option.price)
      }
    },
    getFormattedCurrency: function () {
      return function (amount) {
        amount = parseFloat(amount / 100).toFixed(2)
        if (amount % 1 === 0) {
          amount = parseInt(amount)
        }
        return amount
      }
    }
  }
}
</script>

<style lang="scss">
.subscription-matrix-modal {
  .box {
    .slider {
      width: 256px;

      .range-slider-rail,
      .range-slider-fill {
        height: 10px;
        border-radius: 5.5px;
        background-color: #f4f8fe;
      }
      .range-slider-knob {
        width: 32px;
        height: 32px;
        background-color: transparent;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-image: url(../assets/knob.png);
        background-size: 32px 32px;
      }
    }

    .question {
      margin-top: 16px;
      margin-bottom: 32px;
      font-family: Barlow;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #505050;

      .contact-us {
        color: #124e81;
        cursor: pointer;
      }
    }

    .button-row {
      margin-top: 32px;
      margin-bottom: 32px;

      .primary-button {
        width: 172px;
        height: 54px;
      }
    }

    .content.required {
      height: 100%;
      padding: 40px 69px 22px;

      p.description {
        padding: 0 20px;
        font-family: Barlow;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #505050;
      }

      .slider-box {
        width: 378px;
        height: 306px;
        margin: 32px auto 0;
        padding: 48px 37px 43px 36px;
        border-radius: 15px;
        box-shadow: 0 0 8px 0 rgba(116, 116, 116, 0.1);
        border: solid 1px rgba(80, 80, 80, 0.2);
        background-color: #ffffff;

        h2 {
          margin-bottom: 28px;
          font-family: Poppins;
          font-size: 22px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #124e81;
        }

        .slider {
          margin-top: 41px;
        }

        .info-row {
          .info-item {
            float: left;

            .driver-title,
            .pricing-title {
              min-width: 105px;
              font-family: Poppins;
              font-size: 36px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #124e81;
            }
            .driver-sub-title,
            .pricing-sub-title {
              font-family: Barlow;
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #505050;
            }
          }
        }
        .info-item.driver {
          margin-left: 5px;
          min-width: 150px;
        }
        .info-item.monthly-cost {
          margin-left: 10px;
        }
      }

      .button-row {
        .primary-button {
          margin-left: 24px;
        }
      }
    }

    .content.pricing {
      .slider-box {
        width: 378px;
        height: 487px;
        margin: 32px auto 0;
        padding: 48px 37px 43px 38px;
        border-radius: 15px;
        box-shadow: 0 0 8px 0 rgba(116, 116, 116, 0.1);
        border: solid 1px rgba(80, 80, 80, 0.2);
        background-color: #ffffff;

        h2 {
          margin-bottom: 28px;
          padding: 0 20px;
          font-family: Poppins;
          font-size: 22px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #124e81;
        }

        .slider {
          margin: 41px 0 34px;
        }

        .info-row {
          .info-item {
            .driver-title,
            .pricing-title {
              min-width: 105px;
              font-family: Poppins;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #124e81;
            }
            .driver-sub-title,
            .pricing-sub-title {
              font-family: Barlow;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #505050;
            }
          }

          .info-item.driver {
            .driver-title {
              margin-left: -7px;
              font-size: 49px;
            }
            .driver-sub-title {
              font-size: 21px;
              vertical-align: 1px;
            }
          }

          .info-item.monthly-cost {
            .pricing-title {
              height: 89px;
              font-size: 66px;
            }
            .pricing-sub-title {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
